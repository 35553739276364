@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

:root{
    /* Titulos */
    --sm-title: 2rem;
    --md-title: 2.8rem;
    --lg-title: 3.2rem;
    /* Textos */
    --sm-text: 1.8rem;
    --md-text: 2rem;
    --lg-text: 3rem;
    --xl-text: 3.5rem;
    --xxl-text: 4rem;
    /* Colores */
    --white: #fff;
    --gray:#8e8f92;
    --gray-br:#6d6e71;
    --light-gray:#fff;
    --gray-hover:#8e8f9251;
    --blue:#00c7cc;
    --light-blue:#00e0e4;
}
*{
    margin: 0;
    padding: 0;
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    /* transition: all .3s linear; */
    font-size: 62.5%;
    font-style: normal;
    color:var(--gray);
    outline: 0; 
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

select::-webkit-scrollbar{
    width: 10px;
    background: var(--blue);
}

option:focus{
    background-color: #ffffff;
}

/* Track */
body::-webkit-scrollbar-track {
    background: var(--white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--blue);
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--gray-br);
    transition: all .7s linear;
}


body{
    background: #fff;
    overflow-x: hidden;
    position: relative;
}

.container p::selection, .container h2::selection,
.container li::selection, .menu li a::selection{
    background-color: var(--blue);
    color: var(--white);
}

p::selection, img::selection{
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

a, a:hover, a:focus,
.button, .button:hover, .button:focus{
    text-decoration: none;
    border: none;
    outline: 0;
    cursor: pointer;
}
img:hover{
    cursor: auto;
}
header{
    height: 100%;
    display: grid;
    grid-template-rows: 2;
    position: relative;
}
header.active{
    overflow: hidden;
}

.banner{
    position: relative;
    height: max-content;
}
.banner-img{
    min-height: 680px;
}
.banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner .bg-gradiant{
    width: 100%;
    height: 680px;
    background-color: #ffffff00; /* For browsers that do not support gradients */
    background-image: linear-gradient(
    #ffffff00, 
    #ffffff00, 
    #ffffff00,
    #ffffff00,
    #ffffff0a,
    #ffffff
    );
    z-index: 10;
    position: absolute;top: 0;
}

.banner .logo{
    width: 250px;
    height: 250px;
    position: absolute;
    top: 70px; right: 0;left: 0;
    margin: auto;
    z-index: 100;
}
/* Menu */

.menu{
    width: 0%;
    opacity:0;
    height: 100dvh;
    display: flex;
    overflow-x: hidden;
    gap:20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    margin: auto;
    background-color: #fff;
    z-index: 1000;
    transition: opacity .1s linear;
}

.menu.active{
    width: 100%;
    opacity: 100;
    transition: opacity .3s linear;
}

.menu .home{
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu .home img{
    width: 210px;
    height: 210px;
    object-fit: cover;
}
.menu ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    list-style: none;
}
.menu ul li a{
    cursor: pointer;
    font-size: var(--lg-text);
    transition: all .3s ease-in-out;
    font-weight: 300;
}
.menu ul li a:hover{
    color: var(--blue);
    font-weight: 300;
    font-size: var(--xl-text);
    transform: scale(1.1);
}
.mobile.social-media{
    position: absolute;
    gap: 10px;
    bottom: 50px; right: 0; left: 0;
    margin: auto;
    z-index: 100;
    width: 100%;
    max-width: 375px;
}
.mobile.social-media .icon{
    background: none;
    transition: all .3s ease-in-out;
}
.mobile.social-media .icon:hover{
    transform: scale(1.2);
}
.social-media{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 30px;
    gap: 50px;
}
.social-media .icon{
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s linear;
}
.social-media .icon img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}
.social-media .icon:hover{
    transform: scale(1.2);
}
main{
    display: flex;
    flex-direction: column;
    margin: 40px auto 0px;
    max-width: 1200px;
    transition: none;
    position: relative;
}
.slogan p{
    cursor: auto;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: var(--gray-br);
    font-weight: 300;
    line-height: 35px;
}

.slogan p:nth-child(2){
    font-size: 35px;
}

.slogan p:nth-child(3){
    line-height: 55px;
    font-size: 43px;
    font-weight: 300;
    color: var(--blue);
}

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: max-content;
    gap:10px;
    padding: 25px;
}

.container .container-img{
    height: 350px;
    width: 100%;
    object-fit: cover;
    border-radius: 7px;
}
.info{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    margin-bottom: 7px;
}
.info h2{
    font-size: var(--lg-title);
    color: var(--gray-br);
    font-weight: 300;
    width: max-content;
}
.info img{
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.servicios .info img{
    width: 55px;
    height: 55px;
    object-fit: contain;
}
.descripcion{
    font-size: var(--sm-title);
    color: var(--gray-br);
    font-weight: 200;
    text-align: justify;
}
.mision{
    padding-block: 40px;
    padding-inline: 0;
}
.mision .info, .mision .descripcion{
    padding-inline: 25px;
}
.mision .container-img{
    height: auto;
    max-height: 375px;
    width: 100%;
    z-index:10;
    object-fit: cover;
    border-radius: 7px;
}
.vision{
    background-color: var(--gray-br);
    padding-block: 40px;
}
.vision h2, .vision p{
    color: var(--white);
}
.servicios{
    background-image: url('../assets/img/Edificio.webp');
    background-size: cover;
    background-repeat: no-repeat;
    padding-block:40px;
}
.servicios ul {
    list-style-type: none;
}
.servicios ul li{
    font-size: var(--sm-text);
    font-weight: 300;
    color: var(--gray-br);
    padding-top: 7px;
}
.servicios ul li::before {
    content: "";
    display: inline-block;
    background-color: var(--blue); /* replace with your desired color */
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 2px;
    height: 7px;
    width: 7px;
}
#catalogo{
    gap: 15px;
    padding-bottom: 0;
}
.catalogo{
    padding-bottom: 40px !important;
}
.catalogo_info{
    display: grid;
    gap: 20px;
}
.catalogo .subtitulo{
    font-size: var(--md-text);
    font-weight: 300;
}
.catalogo .titulo{
    font-size: var(--lg-text);
    font-weight: 300;
}
.hidden .titulo,.hidden .subtitulo{
    display: none;
}
.catalogo .portada{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 7px;
    margin-top: 20px;
}
.catalogo .galeria{
    width: 100%;
    height: 177px;
    padding-top: 20px;
}
.catalogo .galeria .swiper-slide .active{
    object-fit: cover;
    width: 90%;
    border: 3.7px solid var(--light-blue);
    border-radius: 7px;
    cursor: auto;
}

.swiper{
    max-width: 100%;
}

.swiper .swiper-scrollbar .swiper-scrollbar-horizontal
.swipper-scrollbar{
    width: 10px !important;
}
.catalogo .galeria img{
    cursor: pointer;
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 7px;
}
.catalogo .precio{
    font-size: var(--md-text);
    font-weight: 300;
    background-color: var(--gray);
    width: max-content;
    padding: 5px 10px;
    color: #fff;
    margin-top: 20px;
    border-radius: 3px;
    box-shadow: 0 1px 5px #c7c7c7;
}
.catalogo .boton{
    font-size: var(--sm-text);
    font-weight: 300;
    background-color: var(--light-blue);
    width: max-content;
    border: none;
    padding: 5px 10px;
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 5px #c7c7c7;
}
.catalogo .descripcion{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   font-size: 20px !important;
   -webkit-line-clamp: 3;
           line-clamp: 3;
   -webkit-box-orient: vertical;
}
.catalogo .descripcion.active{
   -webkit-line-clamp: 10; line-clamp: 10;
   -webkit-box-orient: vertical;
}
.catalogo .detalle.active{
    display: none;
}
.catalogo .detalle{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.catalogo .detalle .detalle_container{
    width:240px;
}
.catalogo .detalle p,
.catalogo .detalle ul li{
    text-align: justify;
    font-size: 18px !important;
    font-weight: 300;
}
.catalogo .detalle p{
    font-weight: 400;
}
.catalogo .detalle ul{
    padding-top: 5px;
    padding-left: 20px;
}
.catalogo .detalle .saber_mas{
    padding-top: 5px;
}
.catalogo .boton::before {
    content: 'Leer más';
}
.catalogo .boton{
    background-color: #00e0e4;
}
.catalogo .boton.active{
    background-color: #6d6e71;
}
.catalogo .boton.active::before {
    content: 'Leer menos';
}
.swiper-button-prev{
    margin-left: 30px;
  }
.swiper-button-next{
    margin-right: 30px;
  }
.swiper-button-prev::after,.swiper-button-next::after{
    color: #6d6e71;
    z-index: 200;
  }

.swiper-pagination-bullets  .swiper-pagination-bullet{
  width: 1px;
  height: 1px;
  margin-top: 15px;
  background: #6d6e71;
}
.swiper-pagination .swiper-pagination-bullet{
  background-color: var(--light-blue);
  padding: 8px;
  opacity: 50%;
  cursor: pointer;
  margin-right: 12px !important;
}
.swiper-pagination .swiper-pagination-bullet-active{
  opacity: 1;
}
.notificacion li{
    background-color: var(--blue);
    font-size: 5rem;
    border:none;
    position: relative;
    box-shadow: 0px 3px 20px var(--gray-hover);
}
.notificacion li div{
    gap: 10px;
    color: var(--white);
}
.notificacion li .button{
    position: absolute;
    margin: auto;
    width: 25px;
    height:25px;
    background-color: #fff;
    box-shadow: 0px 3px 20px var(--gray-hover);
    padding:5px;
    right: -100%;
}
.notificacion li .button svg{
    width: 100%;
    height: 100%;
}
form{
    display: grid;
    gap:50px;
    width: 100%;
    height: auto;
    padding-block: 20px;
}
.PhoneInput{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 15px;
}
.PhoneInputCountry{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.PhoneInputCountrySelect{
    background-color: var(--white);
    color: var(--gray);
    box-shadow: 0px 3px 20px var(--gray-hover);
    font-size: 2rem;
    width: 100%;
    padding: 15px 0;
    margin-left: 15px;
    border-radius: 5px;
    transition: none;
    border: none;
    text-indent: 15px;
}
.PhoneInputCountrySelect option{
    font-size: 1.8rem;
    background: var(--blue);
    color: #fff;
}
.PhoneInputCountrySelect option:checked {
    background-color: var(--gray-br);
}

.PhoneInputCountrySelect option:hover {
    background-color: var(--blue);
}

.PhoneInputCountryIconImg{
    width: 50px;
    height: 50px;
}
.PhoneInputCountry{
    width: 100%;
    height: max-content;
}
label{
    position: relative;
}
.error{
    font-size: 1.6rem;
    position: absolute;
    margin: 0 auto;
    width: max-content;
    left: 0; right: 0;
    padding-top: 7px;
    color: #ff2020;
}
input::selection, textarea::selection{
    background-color: var(--blue);
    color: var(--white);
}
input, textarea{
    background-color: var(--light-gray);
    color: var(--gray);
    box-shadow: 7px 3px 20px var(--gray-hover);
    font-size: 1.6rem;
    width: 100%;
    padding: 15px 0;
    border-radius: 5px;
    transition: none;
    border: none;
    text-indent: 15px;
}
textarea{
    min-height: 200px;
    max-height: 300px;
}
textarea:focus, input:focus,
textarea:focus::placeholder,input:focus::placeholder{
    background: var(--light-blue);
    color: var(--white);
}
form .button{
    background: var(--light-blue);
    color: var(--white);
    width: 95%;
    height: 45px;
    font-size: 1.8rem;
    border-radius: 5px;
    margin: auto;
    transition: all .3s linear;
    box-shadow: 7px 3px 20px var(--gray-hover);
}
form .button:hover,form .button:focus,form .button:active{
    font-weight: bold;
    transform: scale(1.07);
}
.btn-active{
    background: var(--gray-br);
}
footer {
    height:auto;
    padding: 30px 20px;
    background: var(--light-blue);
}
footer p, footer span{
    width: 100%;
    margin: auto;
    text-align: center;
    text-wrap: wrap;
    line-height: 30px;
    color: var(--white);
    font-size: var(--sm-text);
}
@media screen and (min-width:720px){
    .PhoneInput{
        flex-direction: row;
    }
    .banner{
        height: 100dvh;
    }
    .bg-gradiant{
        min-height: 100dvh;
    }
    .slogan{
        position: absolute;
        left: 50px; top: -30px;
    }
    .mision{
        flex-direction: row-reverse;
        gap:40px;
    }
    .mision .container-img{
        height: 400px;
        width: 400px;
    }
    .mision .info{
        padding-top: 100px;
    }
    .vision{
        flex-direction: row;
        gap:40px;
    }
    .vision .container-img{
        height: 300px;
        width: 400px;
    }
    form{
        grid-template-columns: 1fr 1fr;
        max-width: 920px;
        margin: auto;
    }
    form .button{
        grid-column: span 2;
        max-width: 375px;
    }
    .big{
        grid-column: span 2;
    }
    form .button:hover,form .button:focus,form .button:active{
        transform: scale(1.15);
    }
}
@media screen and (min-width:1024px){
    header{
        height: auto;
        margin-bottom: 70px;
    }
    .banner picture img{
        object-fit: cover;
    }
    .banner .logo{
        position: absolute;
        top: 15px; right: 0;left: 30px;
        margin: auto 0;
        z-index: 1;
    }
    main{
        margin: 0 auto;
        padding: 0 30px;
        gap: 50px;
    }
    .vision,.servicios{
        padding-inline: 40px;
    }
    .slogan{
        left: 70px; top: -30px;
    }
    section{
        border-radius: 7px;
    }
    .mobile.social-media{
        display: none;
    }
    .container-toggle{
        display: none;
    }
    .mision .container-img{
        height: 500px;
        width: 600px;
    }
    .menu .logo{
        display: none;
    }
    .vision, .servicios{
        box-shadow: 7px 3px 20px var(--gray-hover);
    }

    .container .info h2{
        font-size: var(--lg-text);
    }

    .container .descripcion{
        font-size: var(--md-text);
    }

    .container ul li{
        font-size: var(--md-text);
    }

    .menu{
        position: fixed;
        padding-right: 30px;
        width: 100%;
        height: 100px;
        display:grid;
        grid-template-columns: repeat(3, max-content);
        justify-content: end;
        opacity: 100;
        gap: 30px;
        margin: auto;
        background: none;
        transition: all .3s linear;
    }
    .menu.visible{
        background-color: var(--blue);
        box-shadow:7px 3px 20px var(--gray-hover);
        color: var(--white);
        opacity: 70%;
    }
    .menu .social-media{
        display: flex;
        padding: 0;
        gap: 20px;
        overflow: hidden;
    }

    .menu .social-media img{
        width:40px;
        height: max-content;
        object-fit: contain;
    }
    .menu .home span{
        max-width: 100px;
        font-size: var(--md-text);
        line-height: 20px;
    }
    .menu ul{
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }
    .menu ul li a{
        font-size: var(--md-text);
        transition: all .3s ease-in-out;
        font-weight : 300;
    }
    .menu.visible ul li a{
        color: var(--white);
    }
    .menu.visible ul li a:hover{
        color: var(--gray-br);
    }
    .menu ul li a:hover{
        transform: scale(1.1);
        font-size: var(--md-text);
    }
    #catalogo{
        gap:30px;
    }
    .catalogo{
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 40px;
    }
    .catalogo_imagenes{
        max-width: 375px;
    }
    .catalogo_imagenes .portada{
        height: 350px;
    }
    .catalogo_imagenes .galeria{
        height: 120px;
    }
    .catalogo_imagenes .galeria img{
        height: 100px;
    }
    .catalogo_imagenes .galeria .swiper-slide:nth-child(1) img{
        height: 95px;
    }
    .catalogo .galeria .swiper-slide .active{
        height: 95px;
    }
    .catalogo_imagenes .mobile{
        display: none;
    }
    .catalogo_info{
        gap: 0px;
        align-items: center;
    }
    .catalogo_info .precio{
        max-height: max-content;
    }
    .catalogo_info .detalle.active{
        display: flex;
    }
    .catalogo_info .boton{
        display: none;
    }
    .catalogo_info .subtitulo{
        font-size: var(--sm-title);
    }
    .catalogo_info .titulo{
        font-size: var(--lg-title);
    }

    .hidden{
        display: flex;
        position: relative;
        align-items: center;
    }
    .hidden .precio{
        position: absolute;
        right: 0;
    }
    .hidden .titulo,.hidden .subtitulo{
        display: block;
    }
    .catalogo .detalle p,
    .catalogo .detalle ul li{
        font-size: var(--sm-text);
    }
    form .button{
        grid-column: 1/2;
        width: 20%;
        margin: 0;
    }
}
@media screen and (min-width:1200px){
    .banner .logo{
        left: 50px;
        width: 320px;
        height: 320px;
    }
    .menu{
        height: 120px;
        gap: 40px;
    }
    .menu .social-media{
        gap:50px;
        height: 100%;
        padding-inline: 15px;
    }
    .menu .social-media img{
        width: 45px;
    }
    .menu ul{
        gap:50px;
    }
    .menu ul li a{
        font-size: var(--lg-text);
    }
    .menu ul li a:hover{
        font-size: var(--xl-text);
    }
    .slogan{
        left: 150px; top: -40px;
    }
    .slogan p{
        font-size: 35px;
        line-height: 40px;
    }
    .slogan p:nth-child(2){
        font-size: 45px;
    }
    .slogan p:nth-child(3){
        line-height: 70px;
        font-size: 53px;
    }
    .container .info h2{
        font-size: var(--xxl-text);
    }

    .container .descripcion{
        font-size: var(--lg-text);
    }

    .container ul li{
        font-size: var(--lg-text);
    }
    .mision .container-img{
        height: 600px;
        width: 600px;
    }
    .vision .container-img{
        height: 350px;
        width: 500px;
    }
    .catalogo
     .detalle .detalle_container{
        width: 270px;
    }
    .error{
        font-size: 2rem;
    }
    input, textarea{
        font-size: 2.2rem;
    }
}