.container-toggle{
  width: 45px;
  height: 45px;
  position: fixed;
  right: 25px;
  top: 40px;
  z-index: 10000;
}
.container-toggle:hover{
  cursor: pointer;
  transform: scale(1.1);
}
.nav-toggle {
    right: 25px;
    display: block;
    top: 40px;
  }
.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
    cursor: pointer;
    border-radius: 3px;
    height: 5px;
    width: 42px;
    background: white;
    position: relative;
    display: block;
    content: '';
    transition: all 250ms ease-in-out;
  }
  .nav-toggle.visible span, .nav-toggle.visible span:before, .nav-toggle.visible span:after{
    background: #00c7cc;
  }
  .nav-toggle span:before {
    top: -15px; 
  }
  .nav-toggle span:after {
    right: -20px;
    bottom: -10px;
    width: 50%;
  }
  
  .nav-toggle.active span {
    background-color: transparent;
  }
  .nav-toggle.active span:before {
    top:0px;
    transform: rotate(-45deg);
    background: #00a7b5;
  }
  .nav-toggle.active span:after {
    bottom:5px;
    right: 0px;
    width: 100%;
    transform: rotate(45deg);
    background: #00a7b5;
  }